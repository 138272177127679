import React from "react"
import { window } from "browser-monads"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  ajax,
  API_CALL_STATUSES,
  getQueryParam,
  getRedirectPathFromUrl,
  getTokenRedirectPathFromUrl,
} from "../utils"
import Progress from "../components/progress"
import FancyInput from "../components/FancyInput"
import FancyLabel from "../components/FancyLabel"

const getErrorMessage = (err, response) => {
  switch (err && err.status ? err.status : "") {
    case 422: {
      return null
    }
    case 403:
      return "Please verify your email"
    case 401:
      return "Username and password do not match"
    case 500:
      return "Cannot access service due to server error. Please try again later."
    default:
      return response && response.error ? response.error : "Failed to login"
  }
}

const { captchaSiteKey } = window

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loginStatus: API_CALL_STATUSES.IDLE,
      email: "",
      password: "",
      errorMessage: "",
      showCaptcha: false,
      grecaptchaValue: "",
      loginChallenge: getQueryParam("login_challenge"),
    }

    this.grecaptchaRef = React.createRef()
  }

  onLoginSubmit = e => {
    e.preventDefault()
    const { email, password, loginChallenge } = this.state
    this.setState({
      loginStatus: API_CALL_STATUSES.PROGRESS,
    })

    ajax({
      path: `user/login${
        !loginChallenge ? "" : `?login_challenge=${loginChallenge}`
      }`,
      type: "POST",
      data: {
        email,
        password,
        login_challenge: loginChallenge,
      },
      success: res => {
        /**
         * Normal login flow
         */
        navigate(
          `/authRedirect/${getTokenRedirectPathFromUrl(
            res.data.token,
            res.data.expiryAt
          )}`
        )
      },
      error: (err, response) => {
        if (err.status == 302) {
          /**
           * Login flow for OIDC. (e.g., when using 'Login using rapyuta.io' in amr_ui)
           *
           * This redirect sends the user to the consent page (/auth/src/pages/consent.js)
           */
          window.location.href = response.data.redirect_to
        } else {
          this.setState({
            loginStatus: API_CALL_STATUSES.ERROR,
          })
          const errorMessage = getErrorMessage(err, response)
          this.setState({
            errorMessage,
            showCaptcha: false,
          })
        }
      },
    })
  }

  updateValue = e => {
    const {
      target: {
        dataset: { id },
        value,
      },
    } = e
    e.preventDefault()
    this.setState({
      [id]: value,
    })
  }

  render() {
    const {
      loginStatus,
      email,
      password,
      errorMessage,
      loginChallenge,
    } = this.state

    /**
     * Remember Me is also not supported when using rapyuta.io as OIDC provider.
     */
    const shouldShowRememberMe = !Boolean(loginChallenge)

    return (
      <Layout dark stickyFooter bgimage>
        <SEO title="Login" />
        {loginStatus === API_CALL_STATUSES.PROGRESS && <Progress />}
        <div className="container w-full h-full flex my-0 mx-auto">
          <div className="w-10/12 ">
            <div className="flex content-center items-center justify-center h-full flex-col">
              <h2 className="text-blueGray-200 bg-gray-900 px-4 py-4">
                <span className="text-red-500">rapyuta.io</span>, now on your
                commandline. Get <span className="text-red-500"> riocli</span>
                <sup className="text-amber-300">&alpha; </sup> today
              </h2>
              <hr />
              <iframe
                src="https://asciinema.org/a/432365/iframe"
                className="w-10/12"
                height="50%"
              ></iframe>
            </div>
          </div>
          <div className="container w-6/12 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 pt-10">
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div className="text-blueGray-400 text-center mb-3 font-bold">
                      {loginStatus === API_CALL_STATUSES.ERROR &&
                        errorMessage && <p id="error">{errorMessage}</p>}
                      <medium>Sign in using your password</medium>
                    </div>
                    <form id="loginForm" onSubmit={this.onLoginSubmit}>
                      <div className="relative w-full mb-3">
                        <FancyLabel htmlFor="email">Email</FancyLabel>
                        <FancyInput
                          data-id="email"
                          type="email"
                          name="email"
                          onChange={this.updateValue}
                          value={email}
                          id="email"
                          autoFocus
                          required
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <FancyLabel htmlFor="password">Password</FancyLabel>
                        <FancyInput
                          type="password"
                          name="password"
                          id="password"
                          data-id="password"
                          onChange={this.updateValue}
                          value={password}
                          required
                        />
                      </div>
                      {shouldShowRememberMe && (
                        <div>
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              id="customCheckLogin"
                              type="checkbox"
                              className="form-checkbox border-0 rounded text-blueGray-800 ml-1 w-5 h-5"
                              style={{ transition: "all .15s ease" }}
                            />
                            <span className="ml-2 text-sm font-semibold text-blueGray-700">
                              Remember me
                            </span>
                          </label>
                        </div>
                      )}
                      <div className="text-center mt-6">
                        <button
                          id="submitButton"
                          className="bg-gray-900 text-white active:bg-blueGray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                          type="submit"
                          style={{ transition: "all .15s ease" }}
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="flex flex-wrap mt-6">
                  <div className="w-1/2">
                    <a
                      href={`/forgotPassword${getRedirectPathFromUrl()}`}
                      className="text-blueGray-300"
                    >
                      <small>Forgot password?</small>
                    </a>
                  </div>
                  <div className="w-1/2 text-right">
                    <a
                      href={`/register${getRedirectPathFromUrl()}`}
                      className="text-blueGray-300"
                    >
                      <small>Create new account</small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Login
